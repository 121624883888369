// Syles
import "../index.css";
// Images
import LogoFont from "../assets/imgs/FontLogo.png";
import Logo from "../assets/imgs/Logo.png";

function Nav() {
  return (
    <nav className="flex w-screen w-full pl-8 pr-8 pt-8 justify-items-center items-center pb-10">
      <div className="flex flex-row md:w-4/5 self-center items-center">
        <a className="w-screen" href="/">
          <img
            className="hidden md:inline-block"
            src={Logo}
            alt="Logo"
            height="114px"
            width="110px"
          />
          <img
            className="inline-block md:hidden"
            src={Logo}
            alt="Logo"
            height="48px"
            width="55.47px"
          />
          <img
            className="inline-block ml-4"
            src={LogoFont}
            alt="LogoFont"
            height="19px"
            width="121px"
          />
        </a>
      </div>
    </nav>
  );
}

export default Nav;
