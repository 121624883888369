import { useRef } from "react";
// Syles
import "../index.css";
// Images
import LogoWhite from "../assets/imgs/LogoWhite.svg";
import Facebook from "../assets/imgs/Facebook.svg";
import Discord from "../assets/imgs/Discord.svg";
import Twitter from "../assets/imgs/Twitter.svg";
import Instagram from "../assets/imgs/Instagram.svg";

function Footer() {
  const scrollToEl = useRef(null);

  return (
    <section
      ref={scrollToEl}
      className="md:columns-2 pl-16 pr-16 mt-16 pt-16"
      style={{ minHeight: "273.01px", backgroundColor: "#1A1C1E" }}
    >
      <div className="w-full">
        <img
          className="inline-block"
          src={LogoWhite}
          alt="Logo"
          height="40px"
          width="251px"
        />
        <h2 className="font-roboto text-2xl font-bold text-white pt-10">
          Social Media
        </h2>
        <section className="flex flex-row flex-wrap pt-2 item-center content-center">
          {/* Add on hover to white 100 */}
          <a href="https://twitter.com/pwnyup" target="_blank" rel="noreferrer">
            <img
              className="mr-4"
              src={Twitter}
              alt="Twitter Icon"
              height="36px"
              width="36px"
            />
          </a>
          <a
            href="https://discord.gg/Ty9FYj2QA5"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="mr-4"
              src={Discord}
              alt="Discord Icon"
              height="36px"
              width="36px"
            />
          </a>
          <a href="https://www.instagram.com/pwnyup/">
            <img
              className="mr-4"
              src={Instagram}
              alt="Instagram Icon"
              height="36px"
              width="36px"
            />
          </a>
          <a
            href="https://www.facebook.com/pwnyup"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="mr-4"
              src={Facebook}
              alt="Facebook Icon"
              height="36px"
              width="36px"
            />
          </a>
        </section>
      </div>

      <div className="md:text-right mt-10 md:mt-0 pb-10">
        <h2 className="font-roboto text-2xl font-bold text-white">Legal</h2>
        <a
          className="block text-base text-gray-300 pt-2"
          href="/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        <a
          className="block text-base text-gray-300 pt-2"
          href="/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <span className="block text-base text-gray-300 pt-2">
          &copy; 2024 PwnyUp. All rights reserved.
        </span>
      </div>
    </section>
  );
}

export default Footer;
