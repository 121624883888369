import { useRef } from "react";
// Components
import { Button } from "../components";
// Syles
import "../index.css";
// Images
import HeroBg from "../assets/imgs/HeroBackground.png";
import LogoFont from "../assets/imgs/FontLogo.png";
import Logo from "../assets/imgs/Logo.png";
import LogoWhite from "../assets/imgs/LogoWhite.svg";
import PhoneHero from "../assets/imgs/PhoneHero.png";
import PhonePick from "../assets/imgs/PhonePick.png";
import PhoneEarn from "../assets/imgs/PhoneEarn.png";
import PhonePlay from "../assets/imgs/PhonePlay.png";
import AppleBadge from "../assets/imgs/AppleBadge.svg";
import GoogleBadge from "../assets/imgs/GoogleBadge.svg";
import Facebook from "../assets/imgs/Facebook.svg";
import Discord from "../assets/imgs/Discord.svg";
import Twitter from "../assets/imgs/Twitter.svg";
import Instagram from "../assets/imgs/Instagram.svg";

function Root() {
  const scrollToEl = useRef(null);

  const executeScroll = () =>
    scrollToEl.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });

  return (
    <div className="min-h-screen w-screen bg-black overflow-x-hidden">
      <div
        className="w-full no-repeat bg-cover"
        style={{
          backgroundImage: `url(${HeroBg})`,
          height: "850px",
        }}
      >
        {/* Header component */}
        <nav className="flex w-screen w-full pl-8 pr-8 pt-8 justify-items-center items-center">
          <div className="flex flex-row md:w-4/5 self-center items-center">
            <a className="w-screen" href="/">
              <img
                className="hidden md:inline-block"
                src={Logo}
                alt="Logo"
                height="114px"
                width="110px"
              />
              <img
                className="inline-block md:hidden"
                src={Logo}
                alt="Logo"
                height="48px"
                width="55.47px"
              />
              <img
                className="inline-block ml-4"
                src={LogoFont}
                alt="LogoFont"
                height="19px"
                width="121px"
              />
            </a>
          </div>
          <Button
            text="Join Now"
            onClick={() => executeScroll()}
            styles="invisible w-32 md:ml-32"
          />
        </nav>

        {/* Page container */}
        <div className="container mx-auto pl-8 pr-8 md:pl-0 md:pr-0">
          {/* Hero CTA */}
          <section class="flex flex-wrap pt-28  item-center content-center justify-center">
            <div class="w-full md:w-3/5">
              <h1 className="font-roboto text-4xl text-center md:text-left md:text-7xl font-black text-white pb-4">
                Think you know sports?
              </h1>
              <h1 className="font-roboto text-4xl text-center md:text-left md:text-7xl font-black text-white">
                It's time to PwnyUp!
              </h1>
              <p className="font-roboto text-2xl text-center md:text-left text-white pt-8 md:w-11/12">
                Pick the outcome of your favorite sports events, earn $PWN & win
                prizes.
              </p>
              <Button
                className="hidden md:inline-block"
                text="Join Now"
                onClick={() => executeScroll()}
                styles="mt-6 w-full md:w-5/12"
              />
            </div>
            <div class="w-full md:w-2/5 mt-14 md:mt-0 md:pl-8">
              <img src={PhoneHero} alt="Phone Hero" height="339.39px" />
            </div>
          </section>

          {/* Pick Section */}
          <section className="md:columns-2 md:h-3/5 mt-24 md:mt-52">
            <div>
              <h2 className="font-roboto text-3xl md:text-6xl text-center md:text-left font-bold text-white">
                Make Your Picks
              </h2>
              <p className="font-roboto text-center md:text-left text-gray-400 text-2xl font-normal pt-4">
                {/* Choose from our daily picks <br />
                and predict the outcomes. */}
                Test your knowledge of the NBA, NFL, eSports and more. Choose
                any of our daily picks and predict the outcomes.
              </p>
              {/* <Button
                text="Join Now"
                onClick={() => executeScroll()}
                styles="mt-6 hidden md:inline-block"
              /> */}
            </div>
            <div className="mt-7 md:mt-0 md:ml-28">
              <img src={PhonePick} alt="Phone - Pick" width="950px" />
            </div>
          </section>
          {/* Play Section */}
          <section className="md:columns-2 md:h-3/5 pt-32">
            <div>
              <h2 className="font-roboto text-3xl text-center md:text-left md:text-6xl md:mr-24 font-bold text-white">
                Play Daily
              </h2>
              <p className="font-roboto text-center md:text-left text-gray-400 text-2xl font-normal pt-4">
                Consistency is king. The more you play the better your chances
                of winning. Plus it's free!
              </p>
              {/* <Button
                text="Join Now"
                onClick={() => executeScroll()}
                styles="mt-6 hidden md:inline-block"
              /> */}
            </div>
            <div className="mt-16 md:mt-0 md:ml-28">
              <img src={PhonePlay} alt="Phone - Play" height="450px" />
            </div>
          </section>
          {/* Earn Section */}
          <section className="md:columns-2 md:h-3/5 pt-32">
            <div>
              <h2 className="font-roboto text-3xl md:text-6xl text-center md:text-left font-bold text-white">
                Earn $PWN
              </h2>
              <p className="font-roboto text-center md:text-left text-gray-400 text-2xl font-normal pt-4">
                Earn $PWN tokens for playing and picking the right outcomes!
                $PWN are redeemable for prizes in our marketplace.
              </p>
              {/* <Button
                text="Join Now"
                onClick={() => executeScroll()}
                styles="mt-6 hidden md:inline-block"
              /> */}
            </div>
            <div className="mt-7 md:mt-0 md:ml-28">
              <img src={PhoneEarn} alt="Phone - Earn" width="950px" />
            </div>
          </section>
          {/* Footer */}
          <section className="pt-24 pl-8 pr-8">
            <h2 className="font-roboto text-3xl md:text-6xl font-bold text-white text-center">
              PwnyUp Today!
            </h2>
            <p className="text-white text-2xl text-center pt-4">
              iOS and Android apps coming soon
            </p>
            <div className=" hidden flex flex-row items-center content-center justify-center pt-8">
              <a href="/">
                <img
                  className="mr-8"
                  src={AppleBadge}
                  alt="Apple App Store Badge"
                  height="40px"
                  width="120px"
                />
              </a>
              <a href="/">
                <img
                  src={GoogleBadge}
                  alt="Google Play Store Badge"
                  height="40px"
                  width="135px"
                />
              </a>
            </div>
          </section>
        </div>
        {/* Footer */}
        <section
          ref={scrollToEl}
          className="md:columns-2 pl-16 pr-16 mt-16 pt-16"
          style={{ minHeight: "273.01px", backgroundColor: "#1A1C1E" }}
        >
          <div className="w-full">
            <img
              className="inline-block"
              src={LogoWhite}
              alt="Logo"
              height="40px"
              width="251px"
            />
            <h2 className="font-roboto text-2xl font-bold text-white pt-10">
              Social Media
            </h2>
            <section className="flex flex-row flex-wrap pt-2 item-center content-center">
              {/* Add on hover to white 100 */}
              <a
                href="https://twitter.com/pwnyup"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Twitter}
                  alt="Twitter Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a
                href="https://discord.gg/Ty9FYj2QA5"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Discord}
                  alt="Discord Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a href="https://www.instagram.com/pwnyup/">
                <img
                  className="mr-4"
                  src={Instagram}
                  alt="Instagram Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a
                href="https://www.facebook.com/pwnyup"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Facebook}
                  alt="Facebook Icon"
                  height="36px"
                  width="36px"
                />
              </a>
            </section>
          </div>

          <div className="md:text-right mt-10 md:mt-0 pb-10">
            <h2 className="font-roboto text-2xl font-bold text-white">Legal</h2>
            <a
              className="block text-base text-gray-300 pt-2"
              href="/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            <a
              className="block text-base text-gray-300 pt-2"
              href="/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <span className="block text-base text-gray-300 pt-2">
              &copy; 2024 PwnyUp. All rights reserved.
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Root;
